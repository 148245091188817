<template>
<b-row class="match-height">
<b-col md="12">
    <div class="daily-review-maintitle"><h2>Staffing</h2></div>
</b-col>
<!-- Driver Details Starts -->
<b-col md="6">
  <b-card-code title="Current Schedule" no-body>
    <b-table
      :sticky-header="stickyHeader"
      :no-border-collapse="noCollapse"

      responsive
      :items="allcurrentScheudles"
      :fields="ScheduleFields"
    >
    <template #cell(driver_name)="data">
        <b-avatar
          class="mr-1"
          :src="data.item.photo"
        /> {{ data.item.driver_name }}
      </template>
      <!-- <template #head(id)>
        <div class="text-nowrap">
          Row ID
        </div>
      </template>

      <template #head()="scope">
        <div class="text-nowrap">
          {{ scope.label }}
        </div>
      </template>

      <template #cell(avatar)="data">
        <b-avatar
          class="mr-1"
          :src="data.value"
        />
      </template>

      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>

      <template #cell()="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template> -->
    </b-table>
  </b-card-code>
</b-col>

    <!-- Driver Details End -->
    <!-- To do Code Starts -->


   <b-col md="6">
    <b-card-code title="Upcoming Schedule" no-body>
        <b-table
        :sticky-header="stickyHeader"
        :no-border-collapse="noCollapse"

        responsive
        :items="allupcomingScheudles"
        :fields="ScheduleFields"
        >
        <template #cell(driver_name)="data">
        <b-avatar
          class="mr-1"
          :src="data.item.photo"
        /> {{ data.item.driver_name }}
      </template>
        </b-table>
    </b-card-code>
    </b-col>

    <!-- To do Code Ends -->


  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import vSelect from 'vue-select'
import moment from "moment";

import {
  BTable, BFormCheckbox, BAvatar, BBadge, BCardBody,BRow,BCol,BCard, BCardHeader, BCardTitle, BCardText,BFormGroup,BMedia,
   BMediaAside, BMediaBody, 
} from 'bootstrap-vue'

export default {
  props: {
    allcurrentScheudles: Array,
    allupcomingScheudles: Array,

    },
  components: {
    BCardCode,
    BTable,
    BCardBody,
    BCardText,
    BCardTitle,
    BCardHeader,
    BCard,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
    selected: { title: 'Active' },
    option: [{ title: 'Active' }, { title: 'Inactive' }],
      stickyHeader: true,
      noCollapse: true,
      fixed: true,
      ScheduleFields:[
      {
          key: "driver_name",
          label: "Driver",
          sortable: true,
        },
        {
          key: "tractor_id",
          label: "Tractor",
          sortable: true
        },
        {
          key: "dayTime",
          label: "Start Time",
          sortable: true,
        },
        {
          key: "date",
          label: "Date",
          sortable: true,
          formatter: (value) => {
            return value=='' ? '' : moment(value).format("MM/DD/YYYY");
          },
        },
      ],

      // fields: [
      //   'id',
      //   {
      //     key: 'avatar', label: 'Avatar',
      //   },
      //   'full_name',
      //   'age',
      //   'experience',
      //   { key: 'status', label: 'Status' },
      // ],
      // to_do_fields: [
      //   'full_name',
      // ],
      fields:[],
      items: [
        {
          id: 1,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/10-small.png'),
          full_name: "Korrie O'Crevy",
          age: '61',
          experience: '1 Year',
          status: 2,
        },
        {
          id: 2,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/1-small.png'),
          full_name: 'Bailie Coulman',
          age: '63',
          experience: '3 Years',
          status: 2,
        },
        {
          id: 3,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/9-small.png'),
          full_name: 'Stella Ganderton',
          age: '66',
          experience: '6 Years',
          status: 5,
        },
        {
          id: 4,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/3-small.png'),
          full_name: 'Dorolice Crossman',
          age: '22',
          experience: '2 Years',
          status: 2,
        },
        {
          id: 5,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/4-small.png'),
          full_name: 'Harmonia Nisius',
          age: '33',
          experience: '3 Years',
          status: 2,
        },
        {
          id: 6,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/5-small.png'),
          full_name: 'Genevra Honeywood',
          age: '61',
          experience: '1 Year',
          status: 1,
        },
        {
          id: 7,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/7-small.png'),
          full_name: 'Eileen Diehn',
          age: '59',
          experience: '9 Years',
          status: 3,
        },
        {
          id: 8,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/9-small.png'),
          full_name: 'Richardo Aldren',
          age: '55',
          experience: '5 Years',
          status: 3,
        },
        {
          id: 9,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/2-small.png'),
          full_name: 'Allyson Moakler',
          age: '39',
          experience: '9 Years',
          status: 5,
        },
        {
          id: 10,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/6-small.png'),
          full_name: 'Merline Penhalewick',
          age: '23',
          experience: '3 Years',
          status: 2,
        },
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
}
</script>
<style>
.code-toggler {
    display:none;
}
.todo-select {
    width:120px;
}
.todo-body .card-header {
    padding:1.1rem 1.5rem;
}
.todo-body .card-body {
    padding:0px;
}
.todo-body hr {
    margin:0px;
}
.todo-body .form-group {
    margin-bottom:0px;
}
.to-do-tick {
    color: #7367f0;
}
</style>