<template>
  <div>
    <!-- <daily-dashboard-section1 /> -->
    <daily-dashboard-section2 />
    <!-- <typography-list />
    <typography-description /> -->

  </div>
</template>


<script>
// import DailyDashboardSection1 from './dailysection1.vue'
import DailyDashboardSection2 from './dailysection2.vue'

export default {
  components: {
    // DailyDashboardSection1,
    DailyDashboardSection2,
  },
}
</script>

