<template>
  <b-row class="match-height">
    <!-- Driver Details Starts -->
    <b-col cols="8">
      <b-card-code title="Driver Details" no-body>
        <b-table
          :sticky-header="stickyHeader"
          :no-border-collapse="noCollapse"
          responsive
          :items="alldata"
          :fields="fields"
        >
        <template #cell(total_miles)="data">
          <!-- {{data.item.total_miles}} -->
          {{ checkTractorMiles(data.item.total_miles,data.item.tractor_id,data.item.driver_id) }}
          <!-- {{ filterData }} {{data.item.total_miles}} -->

      </template>
      
        </b-table>
      </b-card-code>
    </b-col>

    <b-modal
      size="lg"
      id="modal-tall"
      ref="modal-tall"
      :hide-footer="true"
      title=""
    >
    <b-row>
      <b-col class="modalCol">
          <b-avatar class="mr-1" :src="driverPhoto" /> {{ driverName }}
        </b-col>
    </b-row>
      <b-row>
        
        <b-col class="modalCol">
          <label>Clock in</label> <br>
          {{ driverClockIn }}
        </b-col>
        <b-col class="modalCol">
          <label>Clock out</label><br>
          {{ driverClockOut }}
        </b-col>
        <b-col class="modalCol">
          <label>Total Miles</label><br>
          {{ driverTotalMiles }}
        </b-col>
        <b-col class="modalCol">
         <label>Total Trips</label> <br>
          {{ driverTotalTrips }}
        </b-col>

        <b-table
          :sticky-header="stickyHeader"
          :no-border-collapse="noCollapse"
          responsive
          :items="driverData"
          :fields="driverDatafields"
        >
        <template #cell(index)="data">
          {{ data.index + 1 }}
      </template>
    
        </b-table>
      </b-row>
    </b-modal>

    <!-- Driver Details End -->

    <!-- To do Code Starts -->
    <b-col md="4">
      <b-card no-body class="todo-body">
        <b-card-header>
          <b-card-title>To Do</b-card-title>
          <b-card-text class="font-small-2 mr-25 mb-0">
            <!-- <b-form-group>
              <v-select
                v-model="selected"
                :clearable="false"
                label="title"
                class="todo-select"
                placeholder="Active"
                :options="option"
              />
            </b-form-group> -->
          </b-card-text>
        </b-card-header>
        <hr />
        <b-card-body>
          <b-table
            :sticky-header="stickyHeader"
            :no-border-collapse="noCollapse"
            :fixed="fixed"
            :items="alltasks"
            :fields="to_do_fields"
            thead-class="d-none"
            class="todo-table"
          >
            <template #cell()="data" class="todo-summary">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  
                  <!-- For color tick use primary class -->
                  <b-avatar variant="primary" size="lg" v-if="data.item.status == 'completed'">
                    <feather-icon size="24" icon="CheckIcon" />
                  </b-avatar>
                  <b-avatar  size="lg" v-if="data.item.status != 'completed'">
                    <feather-icon size="24" icon="CheckIcon" @click="markCompleted(data.item)"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <!-- for color text add class to-do-tick  -->
                  <h4 class="font-weight-bolder mb-0 to-do-tick" v-if="data.item.status == 'completed'">
                    {{ data.item.name }}
                  </h4>
                  <h4 class="font-weight-bolder mb-0" v-if="data.item.status != 'completed'">
                    {{ data.item.name }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0"> Today </b-card-text>
                </b-media-body>
              </b-media>
            </template>
          </b-table>
        </b-card-body>
      </b-card>
    </b-col>

    <!-- To do Code Ends -->
  </b-row>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BCardBody,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BFormGroup,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import moment from "moment";
import vSelect from "vue-select";
import axios from "axios";
import { serverUri } from "@/config";
import { getUserData } from "@/auth/utils";
import Vue from 'vue';

window.EventBus = new Vue();

export default {
  props: {
    alldata: Array,
    alltasks: Array,
    filterData: Array
  },
  components: {
    BCardCode,
    BTable,
    BCardBody,
    BCardText,
    BCardTitle,
    BCardHeader,
    BCard,
    BFormCheckbox,
    BFormGroup,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    vSelect,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      selected: { title: "Active" },
      option: [{ title: "Active" }, { title: "Inactive" }],
      stickyHeader: true,
      noCollapse: true,
      items: [],
      to_do_fields: [],
      fixed: false,
      driverData: [],
      driverName: "",
      driverPhoto: "",
      driverClockIn: "",
      driverClockOut: "",
      driverTotalMiles: 0,
      driverTotalTrips: 0,
      driverDatafields: [
      {
          key: "index",
          label: "Trip",
          sortable: true,
          
        },
        {
          key: "date",
          label: "Dispatch",
          sortable: true,
          formatter: (value) => {
            return moment(value).format('MM/DD/YYYY');
          },
        },
        {
          key: "origin",
          label: "Origin",
          sortable: true,
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "did",
          label: "Arrive",
          sortable: true,
          formatter: (value) => {
            return '';
          },
        },
        {
          key: "destination",
          label: "destination",
          sortable: true,
          formatter: (value) => {
            return value;
          },
        },
      ],
      fields: [
        {
          key: "driver_name",
          label: "Driver",
          sortable: true,
        },
        {
          key: "tractor_id",
          label: "Tractor",
          sortable: true,
        },
        {
          key: "start_time",
          label: "Clock IN",
          sortable: true,
          formatter: (value) => {
            return value == "" || value == null ? '' : moment(value).format("hh:mm a");
          },
        },
        {
          key: "last_time",
          label: "Clock OUT",
          sortable: true,
          formatter: (value) => {
            return value == "" || value == null ? "" : moment(value).format("hh:mm a");
          },
        },
        {
          key: "total_miles",
          label: "Miles",
          sortable: true
        },
        {
          key: "total_trips",
          label: "Trips",
          sortable: true,
        },
      ],
      to_do_fields: [
        {
          key: "name",
        },
      ],
    };
  },
  methods: {

    checkTractorMiles (miles,tractor,driver_id)
    {
      return miles;

    //   if(this.filterData.tractor_id == tractor && this.filterData.driver_id == driver_id)
    //   {
    //       if(miles>this.filterData.miles)
    //       {
    //         return miles - this.filterData.miles == 0 ? miles : miles - this.filterData.miles ;

    //       } else {

    //         return  this.filterData.miles - miles == 0 ? miles : this.filterData.miles - miles;
    //       }
          
    //   } else if(this.filterData.tractor_id == tractor && this.filterData.driver_id != driver_id)
    //   {

    //     if(miles>this.filterData.miles)
    //       {
    //         return miles - this.filterData.miles == 0 ? miles : miles - this.filterData.miles ;

    //       } else {

    //         return  this.filterData.miles - miles == 0 ? miles : this.filterData.miles - miles;
    //       }
    
    //  } else {
    //     return miles;
    //   }
    },

    viewDriverData(data) {
      this.driverName = data.driver_name;
      this.driverPhoto = data.photo;
      this.driverClockIn = moment(data.clock_in).format("hh:mm a");
      this.driverClockOut = data.last_time == "" || data.last_time == null ? "" : moment(data.last_time).format("hh:mm a");
      this.driverTotalMiles = data.total_miles;
      this.driverTotalTrips = data.total_trips;

      this.$http
        .post(`${serverUri}/trip-sheet-history`, {
            company_id:data.company_id,
            driver_id: data.did,
            start_date:moment(data.date).format("YYYY-MM-DD"),
            end_date:moment(data.date).format("YYYY-MM-DD"),
        })
        .then((response) => { 


          if(response.data.status)
          {
            this.driverData = response.data.data;
          }
          

        });

      this.$refs["modal-tall"].show();
    },

    markCompleted(data) 
    {
      console.log(data);
      this.$http
        .post(`${serverUri}/tasks/update_status`, {
            company_id:data.company_id,
            status:'completed',
            id: data.id,
        })
        .then((response) => { 

          if(response.status)
          {

            EventBus.$emit('getTasksData');

            this.$swal({
                        icon: 'success',
                        title: 'Status Updated',
                        text: 'This task status has been updated.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
          }
          
          
        });
    } 
  },
};
</script>
<style scoped>
.modalCol {
  border: 1px solid #f0e8e8;
    padding: 1px;
    margin: 10px;
    font-size: 11px;
}
span.b-avatar.b-avatar-lg.badge-secondary.rounded-circle {
    cursor: pointer;
}

span.b-avatar.mr-1.badge-secondary.rounded-circle {
    cursor: pointer;
}

</style>
