<template>
<b-row class="match-height">
<b-col md="12">
    <div class="daily-review-maintitle"><h2>Maintenance</h2></div>
</b-col>
<!-- Driver Details Starts -->
<b-col md="6">
  <b-card-code title="Open Defects" no-body>
  <hr>
  <div class="total-defects">
    <span class="top-small-heading">Total Defects:</span> <span class="numbering-result">{{totaldefects}}</span>
  </div>
    <b-table
      :sticky-header="stickyHeader"
      :no-border-collapse="noCollapse"

      responsive
      :items="inspectionList"
      :fields="fields"
    >
    <template #cell(id)="data">

{{ data.item.from_date== null ? '' : DaysOpen(data.item.from_date,'second') }}
         
      </template>

      <template #cell(description)="data">
        <div v-html="data.item.description"></div>
      </template>

    </b-table>
  </b-card-code>
</b-col>

    <!-- Driver Details End -->
    <!-- To do Code Starts -->


   <b-col md="6" class="maintenance-right">
    <b-card title="Upcoming PM" no-body>
            <b-card-header>
            <b-card-title>Upcoming PM</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
                <b-form-group>
                  
                </b-form-group>
            </b-card-text>
            </b-card-header>

        <b-table
        :sticky-header="stickyHeader"
        :no-border-collapse="noCollapse"

        responsive
        :items="PMList"
        :fields="PMfields"
        >

        <template #cell(task_status)="data">
          <span v-if="DaysOpen(data.item.due_date,'second') >5 && DaysOpen(data.item.due_date,'second')<=14" style="background:yellow;color:black">{{ data.item.task_status.toUpperCase() }}</span>
          <span v-if="DaysOpen(data.item.due_date,'second') <=5" style="background:red;color:white">{{ data.item.task_status.toUpperCase() }}</span>
      </template>   
      
        </b-table>
    </b-card>
    </b-col>

    <!-- To do Code Ends -->


  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import vSelect from 'vue-select'

import {
  BTable, BFormCheckbox, BAvatar, BBadge, BCardBody,BRow,BCol,BCard, BCardHeader, BCardTitle, BCardText,BFormGroup,BMedia,
   BMediaAside, BMediaBody, 
} from 'bootstrap-vue';
import moment from "moment";

export default {
  props: {
    totaldefects: Number,
    inspectionList: Array,
    PMList:Array

    },
  components: {
    BCardCode,
    BTable,
    BCardBody,
    BCardText,
    BCardTitle,
    BCardHeader,
    BCard,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
    selected: { title: 'Active' },
    option: [{ title: 'Active' }, { title: 'Inactive' }],
      stickyHeader: true,
      noCollapse: true,
      fixed: true,

      fields: [
        {
          key: "title",
          label: "# Tractor",
          sortable: true,
          formatter: (value) => {
            var Title = value.split(" ");
            return '#'+Title[0];
          },
        },
        {
          key: "from_date",
          label: "Date Reported",
          sortable: true,
          formatter: (value) => {
            return value==null ? '' : moment(value).format("MM/DD/YYYY");
          },
        },

        {
          key: "id",
          label: "Days Open",
          sortable: true,
          formatter: (value) => {
            return value;
          },
        },
  
        {
          key: "description",
          label: "Description",
          sortable: true,
         
        },
      ],

      PMfields: [
        {
          key: "title",
          label: "# Tractor",
          sortable: true,
          formatter: (value) => {
            var Title = value.split(" ");
            return '#'+Title[0];
          },
        },
        {
          key: "task_status",
          label: "Status",
          sortable: true,
          formatter: (value) => {
            return value.toUpperCase();
          },
        },
        {
          key: "due_date",
          label: "Due Date",
          sortable: true,
          formatter: (value) => {
            return value==null ? '' : moment(value).format("MM/DD/YYYY");
          },
        },
      ],
     
    }
  },
  methods : {

    DaysOpen(due_date,type=null)
    {

      const date1 = new Date();
      const date2 = new Date(due_date);

      if(date2 > date1)
      {
        var Difference_In_Time = date2.getTime() - date1.getTime();

        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        if(type == 'second')
        {
          return parseInt(Math.abs(Difference_In_Days));

        } else {
          return Difference_In_Days+' days';
        }

      } else {


        if(type == 'second')
        {
          var Difference_In_Time = date2.getTime() - date1.getTime();

          var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

          return parseInt(Math.abs(Difference_In_Days));

        } else {

            var Difference_In_Time = date2.getTime() - date1.getTime();

            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

            return parseInt(Math.abs(Difference_In_Days))+' days';
        }

      }

    }
  }
}
</script>
<style>
.code-toggler {
    display:none;
}
.todo-select {
    width:120px;
}
.maintenance-right .card-header {
    padding:1.1rem 1.5rem;
}
.todo-body .card-body {
    padding:0px;
}
hr {
    margin:0px;
}
.maintenance-right .form-group {
    margin-bottom:0px;
}
.to-do-tick {
    color: #7367f0;
}

.total-defects{
    padding: 1rem 1.5rem;
}
.maintenance-right .b-table-sticky-header {
    overflow-y: auto;
    max-height: 350px;
}
.numbering-result {
    font-size: 24px;
    font-weight: 700!important;
    line-height: 33px;
    letter-spacing: 0;
    color: #7200f0;
}
.top-small-heading {
    font-size: 16px!important;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    opacity: .8;
}
</style>